import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const Mesotherapie = ({ data, location, ...props }) => {
    return (
        <Layout location={location} title="Mesotherapie" noSlider {...props}>
            <p>
                Bei der Mesotherapie werden in die mittlere (meso) Hautschicht
                viele kleine Injektionen mit niedrigdosierten Wirkstoffen
                eingebracht. Wir wenden diese Behandlungsform speziell bei den
                verschiedenen Formen des Haarausfalles an.
            </p>

            <Questions title="Mesotherapie" />
        </Layout>
    );
};

export default Mesotherapie;
